<template>
    <div class="lookWages">
        <div class="content" v-if="list.length">
            <div class="cell" v-for="item in list" :key="item.index" @click="goDetails(item)">
                <img src="@/assets/img/lookWages.png" alt="">
                <div class="right_info">
                    <div class="title">
                        <div>{{item.detail_name}}</div>
                        <van-icon class="icon" name="arrow" />
                    </div>
                    <div class="wages">
                        <div>{{item.loan_time}}</div>
                        <div>+{{item.actual_pay}}元</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="null-data">
            <div>
                <img :src="src+'null_index.png'" />
                <div>暂无记录</div>
            </div>
        </div>
        <div class="bottom_back">
            <div class="bottom_back_on" @click="onClickLeft">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import '@/assets/css/index.css'
import config from "@/utils/config.js";
export default {
    data() {
        return {
            list: [],
            src: `${config.ossImageUrl}`,
        }
    },
    mounted() {
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        this.getInfo()

    },
    methods: {
        goDetails(e){
            sessionStorage.setItem('lookWages', JSON.stringify(e))
            this.$router.push('/lookWagesDetails')
        },
        gobanck() {
            this.$router.replace(-1);
        },
        getInfo() {
            // 获取信息
            this.Request('client/payroll/list', 'get', { team_id: localStorage.getItem('team_id') }).then(res => {
                if (res.status == 0) {
                    this.list = res.data.list
                }
            })
        },
        onClickLeft() {
            this.$router.replace('/home')
        },
    }
}
</script>
<style scoped>
.lookWages {
    min-height: calc(100vh - 24px);
    background: #f8f8f8;
}

.lookWages > .content {
    margin-top: 24px;
    background-color: #fff;
}
.lookWages .cell {
    padding:30px;
    display: flex;
    border-bottom: 1px solid #f8f8f8;
}
.lookWages .cell img {
    width: 70px;
    height: 70px;
}
.lookWages .cell .right_info {
    margin-left: 16px;
    width: 100%;
}
.lookWages .cell .right_info > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lookWages .cell .right_info .title > div:first-of-type {
    font-weight: 700;
    color: #1a1a1a;
}
.lookWages .cell .right_info .title > .icon {
    color: #999;
    font-size: 26px;
}
.lookWages .cell .right_info .wages {
    margin-top: 10px;
}
.lookWages .cell .right_info .wages > div:first-of-type {
    font-size: 24px;
    color: #999;
}
.lookWages .cell .right_info .wages > div:last-of-type {
    color: #f62e2e;
    font-size: 32px;
    font-weight: 700;
}
</style>